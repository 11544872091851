<template>
  <div>
    <div class="card mb-5">
      <header class="card-header">
        <p class="card-header-title">
          {{ $t('push.title') }}
        </p>
      </header>
      <div class="card-content">
        <p class="title is-6">{{ $t('push.isPushNeeded') }} *</p>
        <b-field>
          <b-radio-button v-model="form.push.isPushNeeded" :native-value="true" type="is-primary" @input="onChange">
            <b-icon icon="check"></b-icon>
            <span>{{ $t('yes') }}</span>
          </b-radio-button>
          <b-radio-button v-model="form.push.isPushNeeded" :native-value="false" type="is-primary" @input="onChange">
            <b-icon icon="close"></b-icon>
            <span>{{ $t('no') }}</span>
          </b-radio-button>
        </b-field>
      </div>
    </div>

    <template v-if="form.push.isPushNeeded">

      <div class="box">
        <p class="title is-6">{{ $t('push.mobileAppCount') }}</p>
        <b-field label="">
          <b-numberinput v-model="form.push.mobileAppCount" min="0" max="999"></b-numberinput>
        </b-field>
      </div>

      <div class="box">
        <b-field :label="$t('push.mobileAppDownloads')">
          <b-input type="text" v-model="form.push.mobileAppDownloads" placeholder=""></b-input>
        </b-field>
      </div>

      <div class="box">
        <b-field :label="$t('push.mobileAppActiveUsers')">
          <b-input type="text" v-model="form.push.mobileAppActiveUsers" placeholder=""></b-input>
        </b-field>
      </div>

      <div class="box">
        <p class="title is-6" v-html="$t('push.osRatio')"></p>
        <div class="mb-5 has-text-centered">iOS / Android</div>
        <b-field>
          <!-- <b-input type="text" v-model="form.push.osRatio" placeholder=""></b-input> -->
          <b-slider v-model="form.push.osRatio" :custom-formatter="(val) => `${val}/${100-val}%`" :tooltip="false" indicator></b-slider>
        </b-field>
      </div>

      <div class="box">
        <b-field :label="$t('push.pushDeliveryPercent')">
          <b-numberinput v-model="form.push.pushDeliveryPercent" min="0" max="100"></b-numberinput>
        </b-field>
      </div>

      <div class="box">
        <p class="title is-6">{{ $t('push.isCascadingNeeded') }}</p>
        <div class="mb-5">{{ $t('push.isCascadingNeededDescription') }}</div>
        <b-field>
          <b-radio-button v-model="form.push.isCascadingNeeded" :native-value="$t('yes')" type="is-primary">
            <b-icon icon="check"></b-icon>
            <span>{{ $t('yes') }}</span>
          </b-radio-button>
          <b-radio-button v-model="form.push.isCascadingNeeded" :native-value="$t('no')" type="is-primary">
            <b-icon icon="close"></b-icon>
            <span>{{ $t('no') }}</span>
          </b-radio-button>
        </b-field>
        <b-field :label="$t('comment')">
          <b-input-auto v-model="form.push.isCascadingNeededComment" placeholder=""/>
        </b-field>
      </div>

      <div class="box">
        <p class="title is-6">{{ $t('push.isPushDirectNeeded') }}</p>
        <div class="mb-5">{{ $t('push.isPushDirectNeededDescription') }}</div>
        <b-field>
          <b-radio-button v-model="form.push.isPushDirectNeeded" :native-value="$t('yes')" type="is-primary">
            <b-icon icon="check"></b-icon>
            <span>{{ $t('yes') }}</span>
          </b-radio-button>
          <b-radio-button v-model="form.push.isPushDirectNeeded" :native-value="$t('no')" type="is-primary">
            <b-icon icon="close"></b-icon>
            <span>{{ $t('no') }}</span>
          </b-radio-button>
        </b-field>
        <b-field :label="$t('comment')">
          <b-input-auto v-model="form.push.isPushDirectNeededComment" placeholder=""/>
        </b-field>
      </div>

    </template>

    <div class="mt-5 mb-5">
      <div class="mt-5 mb-5 is-size-7">* {{ $t('requiredField') }}</div>

      <b-button class="mr-5" @click="$emit('back')">{{ $t('back') }}</b-button>
      <b-button class="mr-5" @click="$emit('next')">{{ $t('next') }}</b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import bInputAuto from '@/components/bInputAuto.vue'

export default {
  components: {
    bInputAuto
  },
  computed: mapState([
    'form'
  ]),
  created() {
    this.onChange(this.form.push.isPushNeeded)
  },
  methods: {
    onChange(val) {
      const index = this.form.questions.supposedToUse.indexOf('Push')
      if (val) {
        if (index === -1) {
          this.form.questions.supposedToUse.push('Push')
        }
      } else {
        if (index > -1) {
          this.form.questions.supposedToUse.splice(index, 1)
        }
      }
    }
  }
}
</script>
