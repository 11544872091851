<template>
  <b-input
      type="textarea"
      v-model="modelValue"
      v-on="$listeners"
      v-bind="$attrs"
      @input.native="onInput"
      rows="1"
  />
</template>

<script>
export default {
  name: "bInputAuto",
  props: {
    value: String
  },
  data() {
    return {
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    onInput(event) {
      event.target.style.height = 0;
      event.target.style.height = (event.target.scrollHeight) + "px";
    },
  },
  mounted() {
    let el = this.$el.querySelector('textarea')
    el.setAttribute("style", "height:" + (el.scrollHeight) + "px;overflow-y:hidden;");
  }
};
</script>
