<template>
  <div>
    <div class="card mb-5">
      <header class="card-header">
        <p class="card-header-title">
          {{ $t('sms.title') }}
        </p>
      </header>
      <div class="card-content">
        <p class="title is-6">{{ $t('sms.isSmsNeeded') }} *</p>
        <b-field>
          <b-radio-button v-model="form.sms.isSmsNeeded" :native-value="true" type="is-primary" @input="onChange">
            <b-icon icon="check"></b-icon>
            <span>{{ $t('yes') }}</span>
          </b-radio-button>
          <b-radio-button v-model="form.sms.isSmsNeeded" :native-value="false" type="is-primary" @input="onChange">
            <b-icon icon="close"></b-icon>
            <span>{{ $t('no') }}</span>
          </b-radio-button>
        </b-field>
      </div>
    </div>

    <template v-if="form.sms.isSmsNeeded">

      <div class="box">
        <p class="title is-6">{{ $t('sms.nowSmsSending') }}</p>
        <div class="mb-5">{{ $t('sms.nowSmsSendingDescription') }}</div>
        <b-field>
          <b-checkbox v-model="form.sms.nowSmsSending" :native-value="$t('sms.nowSmsSendingValues.notSending')">
            {{ $t('sms.nowSmsSendingValues.notSending') }}
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="form.sms.nowSmsSending" :native-value="$t('sms.nowSmsSendingValues.selfMade')">
            {{ $t('sms.nowSmsSendingValues.selfMade') }}
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="form.sms.nowSmsSending" :native-value="$t('sms.nowSmsSendingValues.otherVendor')">
            {{ $t('sms.nowSmsSendingValues.otherVendor') }}
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="form.sms.nowSmsSending" :native-value="$t('sms.nowSmsSendingValues.cloudSoft')">
            {{ $t('sms.nowSmsSendingValues.cloudSoft') }}
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="form.sms.nowSmsSending" :native-value="$t('other')">
            {{ $t('other') }}
          </b-checkbox>
        </b-field>
        <b-field>
          <b-input-auto v-model="form.sms.nowSmsSendingComment" placeholder=""/>
        </b-field>
      </div>

      <div class="box">
        <p class="title is-6">{{ $t('sms.isIncomingSmsNeeded') }}</p>
        <b-field>
          <b-radio-button v-model="form.sms.isIncomingSmsNeeded" :native-value="$t('yes')" type="is-primary">
            <b-icon icon="check"></b-icon>
            <span>{{ $t('yes') }}</span>
          </b-radio-button>
          <b-radio-button v-model="form.sms.isIncomingSmsNeeded" :native-value="$t('no')" type="is-primary">
            <b-icon icon="close"></b-icon>
            <span>{{ $t('no') }}</span>
          </b-radio-button>
        </b-field>
      </div>

      <div class="box">
        <p class="title is-6">{{ $t('sms.isIMSICheckNeeded') }}</p>
        <div class="mb-5">{{ $t('sms.isIMSICheckNeededDescription') }}</div>
        <b-field>
          <b-radio-button v-model="form.sms.isIMSICheckNeeded" :native-value="$t('yes')" type="is-primary">
            <b-icon icon="check"></b-icon>
            <span>{{ $t('yes') }}</span>
          </b-radio-button>
          <b-radio-button v-model="form.sms.isIMSICheckNeeded" :native-value="$t('no')" type="is-primary">
            <b-icon icon="close"></b-icon>
            <span>{{ $t('no') }}</span>
          </b-radio-button>
        </b-field>
      </div>

      <div class="box">
        <b-field :label="$t('sms.currentSmsLimitations')">
          <b-input-auto v-model="form.sms.currentSmsLimitations" placeholder=""/>
        </b-field>
      </div>

    </template>

    <div class="mt-5 mb-5">
      <div class="mt-5 mb-5 is-size-7">* {{ $t('requiredField') }}</div>

      <b-button class="mr-5" @click="$emit('back')">{{ $t('back') }}</b-button>
      <b-button class="mr-5" @click="$emit('next')">{{ $t('next') }}</b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import bInputAuto from '@/components/bInputAuto.vue'

export default {
  components: {
    bInputAuto
  },
  computed: mapState([
    'form'
  ]),
  created() {
    this.onChange(this.form.sms.isSmsNeeded)
  },
  methods: {
    onChange(val) {
      const index = this.form.questions.supposedToUse.indexOf('SMS')
      if (val) {
        if (index === -1) {
          this.form.questions.supposedToUse.push('SMS')
        }
      } else {
        if (index > -1) {
          this.form.questions.supposedToUse.splice(index, 1)
        }
      }
    }
  }
}
</script>
