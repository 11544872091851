<template>
  <div>
    <ValidationObserver ref="validator" v-slot="{ errors }">
      <div class="box">
        <ValidationProvider vid="name" :name="$t('name')" rules="required">
          <b-field :type="errors.name && errors.name.length ? 'is-danger' : ''" :message="errors.name" :label="$t('nameLabel') + ' *'">
            <b-input type="text" v-model.trim="form.name" :placeholder="$t('name')"></b-input>
          </b-field>
        </ValidationProvider>
      </div>
      <div class="box">
        <ValidationProvider vid="email" name="Email" rules="required|email|non_cyrillic">
          <b-field :type="errors.email && errors.email.length ? 'is-danger' : ''" :message="errors.email" :label="$t('emailLabel') + ' *'">
            <b-input type="email" v-model.trim="form.email" placeholder="Email"></b-input>
          </b-field>
        </ValidationProvider>
      </div>
      <div class="box">
        <ValidationProvider vid="phone" :name="$t('phone')" rules="required|phone">
          <b-field :type="errors.phone && errors.phone.length ? 'is-danger' : ''" :message="errors.phone" :label="$t('phone') + ' *'" required>
            <b-input type="tel" v-model.trim="form.phone" :placeholder="$t('phone')"></b-input>
          </b-field>
        </ValidationProvider>
      </div>
      <div class="box">
        <ValidationProvider vid="site" :name="$t('site')" rules="url">
          <b-field :type="errors.site && errors.site.length ? 'is-danger' : ''" :message="errors.site" :label="$t('site')">
            <b-input type="text" v-model.trim="form.site" :placeholder="$t('site')"></b-input>
          </b-field>
        </ValidationProvider>
      </div>
      <div class="box">
        <ValidationProvider vid="company" :name="$t('company')" rules="">
          <b-field :type="errors.company && errors.company.length ? 'is-danger' : ''" :message="errors.company" :label="$t('company')">
            <b-input-auto v-model.trim="form.company" :placeholder="$t('company')" />
          </b-field>
        </ValidationProvider>
      </div>
      </ValidationObserver>

      <div class="mt-5 mb-5">
        <div class="mt-5 mb-5 is-size-7">* {{ $t('requiredField') }}</div>

        <b-button class="mr-5" @click="next()">{{ $t('next') }}</b-button>
        <b-button class="fr" type="is-danger is-light" @click="$emit('clear')" v-if="showClear">{{ $t('clear') }}</b-button>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import bInputAuto from './bInputAuto.vue'

export default {
  props: {
    showClear: Boolean
  },
  components: {
    bInputAuto
  },
  computed: mapState([
    'form'
  ]),
  methods: {
    async next() {
      if (!await this.$refs.validator.validate()) {
        // console.log(this.$refs.validator.errors)
        return
      }
      this.$emit('next')
    }
  }
}
</script>
