import Vue from 'vue';
import { extend, localize, ValidationProvider, ValidationObserver }  from "vee-validate";
import { confirmed, email, required, max, max_value, min, min_value, numeric } from 'vee-validate/dist/rules';
import ru from 'vee-validate/dist/locale/ru.json';
import en from 'vee-validate/dist/locale/en.json';

extend('required', required);
extend('confirmed', confirmed);
extend('email', email);
extend('max', max);
extend('min', min);
extend('max_value', max_value);
extend('min_value', min_value);
extend('numeric', numeric);
extend('url', value => /^(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/.test(value));
extend('phone', value => /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/im.test(value));
extend('non_cyrillic', value => /^[^А-Яа-яёЁ]*$/.test(value));


ru.messages.url = 'Поле {_field_} содержит не корректный url'
ru.messages.phone = 'Поле {_field_} содержит не корректный номер телефона'
ru.messages.non_cyrillic = 'Поле {_field_} не может содержать кириллические символы'

localize({ru, en});
localize(localStorage.getItem('locale') || 'ru');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);