<template>
  <div>
    <div class="card mb-5">
      <header class="card-header">
        <p class="card-header-title">
          {{ $t('extendedSupport.title') }}
        </p>
      </header>
      <div class="card-content">
        <p class="title is-6">{{ $t('extendedSupport.supportThirdParty') }}</p>
        <div class="mb-5">{{ $t('extendedSupport.supportThirdPartyDescription') }}</div>
        <b-field>
          <b-radio-button v-model="form.extendedSupport.supportThirdParty" :native-value="$t('yes')" type="is-primary">
            <b-icon icon="check"></b-icon>
            <span>{{ $t('yes') }}</span>
          </b-radio-button>
          <b-radio-button v-model="form.extendedSupport.supportThirdParty" :native-value="$t('no')" type="is-primary">
            <b-icon icon="close"></b-icon>
            <span>{{ $t('no') }}</span>
          </b-radio-button>
        </b-field>
        <b-field :label="$t('comment')">
          <b-input-auto v-model="form.extendedSupport.supportThirdPartyComment" placeholder=""/>
        </b-field>
      </div>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('extendedSupport.isMonitoringNeeded') }}</p>
      <div class="mb-5">{{ $t('extendedSupport.isMonitoringNeededDescription') }}</div>
        <b-field>
          <b-radio-button v-model="form.extendedSupport.isMonitoringNeeded" :native-value="$t('yes')" type="is-primary">
            <b-icon icon="check"></b-icon>
            <span>{{ $t('yes') }}</span>
          </b-radio-button>
          <b-radio-button v-model="form.extendedSupport.isMonitoringNeeded" :native-value="$t('no')" type="is-primary">
            <b-icon icon="close"></b-icon>
            <span>{{ $t('no') }}</span>
          </b-radio-button>
        </b-field>
        <b-field :label="$t('comment')">
          <b-input-auto v-model="form.extendedSupport.isMonitoringNeededComment" placeholder=""/>
        </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('extendedSupport.isAlertReactionNeeded') }}</p>
      <div class="mb-5">{{ $t('extendedSupport.isAlertReactionNeededDescription') }}</div>
      <b-field>
        <b-radio-button v-model="form.extendedSupport.isAlertReactionNeeded" :native-value="$t('yes')" type="is-primary">
          <b-icon icon="check"></b-icon>
          <span>{{ $t('yes') }}</span>
        </b-radio-button>
        <b-radio-button v-model="form.extendedSupport.isAlertReactionNeeded" :native-value="$t('no')" type="is-primary">
          <b-icon icon="close"></b-icon>
          <span>{{ $t('no') }}</span>
        </b-radio-button>
      </b-field>
      <b-field :label="$t('comment')">
        <b-input-auto v-model="form.extendedSupport.isAlertReactionNeededComment" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('extendedSupport.isProActiveMonitoringNeeded') }}</p>
      <div class="mb-5">{{ $t('extendedSupport.isProActiveMonitoringNeededDescription') }}</div>
      <b-field>
        <b-radio-button v-model="form.extendedSupport.isProActiveMonitoringNeeded" :native-value="$t('yes')" type="is-primary">
          <b-icon icon="check"></b-icon>
          <span>{{ $t('yes') }}</span>
        </b-radio-button>
        <b-radio-button v-model="form.extendedSupport.isProActiveMonitoringNeeded" :native-value="$t('no')" type="is-primary">
          <b-icon icon="close"></b-icon>
          <span>{{ $t('no') }}</span>
        </b-radio-button>
      </b-field>
      <b-field :label="$t('comment')">
        <b-input-auto v-model="form.extendedSupport.isProActiveMonitoringNeededComment" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('extendedSupport.isInstallUpdateNeeded') }}</p>
      <div class="mb-5">{{ $t('extendedSupport.isInstallUpdateNeededDescription') }}</div>
      <b-field>
        <b-radio-button v-model="form.extendedSupport.isInstallUpdateNeeded" :native-value="$t('yes')" type="is-primary">
          <b-icon icon="check"></b-icon>
          <span>{{ $t('yes') }}</span>
        </b-radio-button>
        <b-radio-button v-model="form.extendedSupport.isInstallUpdateNeeded" :native-value="$t('no')" type="is-primary">
          <b-icon icon="close"></b-icon>
          <span>{{ $t('no') }}</span>
        </b-radio-button>
      </b-field>
      <b-field :label="$t('comment')">
        <b-input-auto  v-model="form.extendedSupport.isInstallUpdateNeededComment" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('extendedSupport.additionalComment') }}</p>
      <div class="mb-5">{{ $t('extendedSupport.additionalCommentDescription') }}</div>
      <b-field label="">
        <b-input-auto v-model="form.extendedSupport.additionalComment" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('extendedSupport.usabilityRate') }}</p>
      <!-- <div class="mb-5">Где 5 - очень удобно.</div> -->
      <div class="has-text-centered">
        <b-rate v-model="form.extendedSupport.usabilityRate" size="is-large" spaced/>
      </div>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('extendedSupport.whatToAdd') }}</p>
      <div class="mb-5"></div>
      <b-field label="">
        <b-input-auto v-model="form.extendedSupport.whatToAdd" placeholder=""/>
      </b-field>
    </div>

    <div class="mt-5 mb-5">
      <div class="mt-5 mb-5 is-size-7">* {{ $t('requiredField') }}</div>

      <b-button class="mr-5" @click="$emit('back')">{{ $t('back') }}</b-button>
      <b-button class="fr" @click="$emit('submit')" type="is-success">{{ $t('submit') }}</b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import bInputAuto from '@/components/bInputAuto.vue'

export default {
  components: {
    bInputAuto
  },
  computed: mapState([
    'form'
  ]),
}
</script>
