<template>
  <div class="container">
    <div class="noprint">
      <navbar-component/>

      <b-button @click="print()" class="fr is-hidden-touch" outlined>Печать</b-button>

      <b-breadcrumb align="is-left">
        <b-breadcrumb-item tag='router-link' to="/admin">Назад</b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <h3 class="title is-3 has-text-centered" v-if="item">{{ item.company ? item.company : item.name }}</h3>
    <div v-else class="title has-text-centered">404 Страница не найдена</div>

    <template v-if="item">
    <div class="box">
      <div>{{ $t('name') }}: <strong>{{ item.name }}</strong></div>
      <div>{{ $t('email') }}: <strong>{{ item.email }}</strong></div>
      <div>{{ $t('phone') }}: <strong>{{ item.phone }}</strong></div>
      <div>{{ $t('site') }}: <strong>{{ item.site }}</strong></div>
      <div>{{ $t('company') }}: <strong>{{ item.company }}</strong></div>
    </div>

    <template v-if="item.sms.isSmsNeeded">
      <div class="box">
        <p class="title is-6">{{ $t('sms.title') }}</p>
        <div v-if="item.sms.nowSmsSending.length || item.sms.nowSmsSendingComment">{{ $t('sms.nowSmsSending') }}: <strong>{{ item.sms.nowSmsSending.join(', ') }}</strong></div>
        <div v-if="item.sms.nowSmsSendingComment">{{ $t('comment') }}: <strong>{{ item.sms.nowSmsSendingComment }}</strong></div>
        <div v-if="item.sms.isIncomingSmsNeeded">{{ $t('sms.isIncomingSmsNeeded') }}: <strong>{{ item.sms.isIncomingSmsNeeded }}</strong></div>
        <div v-if="item.sms.isIMSICheckNeeded">{{ $t('sms.isIMSICheckNeeded') }}: <strong>{{ item.sms.isIMSICheckNeeded }}</strong></div>
        <div v-if="item.sms.currentSmsLimitations">{{ $t('sms.currentSmsLimitations') }}: <strong>{{ item.sms.currentSmsLimitations }}</strong></div>
      </div>
    </template>

    <template v-if="item.push.isPushNeeded">
      <div class="box">
        <p class="title is-6">{{ $t('push.title') }}</p>
        <div v-if="item.push.mobileAppCount">{{ $t('push.mobileAppCount') }}: <strong>{{ item.push.mobileAppCount }}</strong></div>
        <div v-if="item.push.mobileAppDownloads">{{ $t('push.mobileAppDownloads') }}: <strong>{{ item.push.mobileAppDownloads }}</strong></div>
        <div v-if="item.push.mobileAppActiveUsers">{{ $t('push.mobileAppActiveUsers') }}: <strong>{{ item.push.mobileAppActiveUsers }}</strong></div>
        <div v-if="item.push.osRatio">{{ $t('push.osRatio') }}: <strong>{{ item.push.osRatio }}/{{ 100 - item.push.osRatio }}%</strong></div>
        <div v-if="item.push.pushDeliveryPercent">{{ $t('push.pushDeliveryPercent') }}: <strong>{{ item.push.pushDeliveryPercent }}</strong></div>
        <div v-if="item.push.isCascadingNeeded || item.push.isCascadingNeededComment">{{ $t('push.isCascadingNeeded') }}: <strong>{{ item.push.isCascadingNeeded }}</strong></div>
        <div v-if="item.push.isCascadingNeededComment">{{ $t('comment') }}: <strong>{{ item.push.isCascadingNeededComment }}</strong></div>
        <div v-if="item.push.isPushDirectNeeded || item.push.isPushDirectNeededComment">{{ $t('push.isPushDirectNeeded') }}: <strong>{{ item.push.isPushDirectNeeded }}</strong></div>
        <div v-if="item.push.isPushDirectNeededComment">{{ $t('comment') }}: <strong>{{ item.push.isPushDirectNeededComment }}</strong></div>
      </div>
    </template>

    <template v-if="item.call.isCallNeeded">
      <div class="box">
        <p class="title is-6">{{ $t('call.title') }}</p>
        <div v-if="item.call.isFlashCallNeeded || item.call.isFlashCallNeededComment">{{ $t('call.isFlashCallNeeded') }}: <strong>{{ item.call.isFlashCallNeeded }}</strong></div>
        <div v-if="item.call.isFlashCallNeededComment">{{ $t('comment') }}: <strong>{{ item.call.isFlashCallNeededComment }}</strong></div>
        <div v-if="item.call.simultaneousCalls">{{ $t('call.simultaneousCalls') }}: <strong>{{ item.call.simultaneousCalls }}</strong></div>
        <div v-if="item.call.callDuration">{{ $t('call.callDuration') }}: <strong>{{ item.call.callDuration }}</strong></div>
        <div v-if="item.call.isIncomingCallsNeeded || item.call.isIncomingCallsNeededComment">{{ $t('call.isIncomingCallsNeeded') }}: <strong>{{ item.call.isIncomingCallsNeeded }}</strong></div>
        <div v-if="item.call.isIncomingCallsNeededComment">{{ $t('comment') }}: <strong>{{ item.call.isIncomingCallsNeededComment }}</strong></div>
        <div v-if="item.call.internetProvider || item.call.internetProviderComment">{{ $t('call.internetProvider') }}: <strong>{{ item.call.internetProvider }}</strong></div>
        <div v-if="item.call.internetProviderComment">{{ $t('comment') }}: <strong>{{ item.call.internetProviderComment }}</strong></div>
        <div v-if="item.call.callProtocol || item.call.callProtocolComment">{{ $t('call.callProtocol') }}: <strong>{{ item.call.callProtocol }}</strong></div>
        <div v-if="item.call.callProtocolComment">{{ $t('comment') }}: <strong>{{ item.call.callProtocolComment }}</strong></div>
        <div v-if="item.call.isSpeechNeeded">{{ $t('call.isSpeechNeeded') }}: <strong>{{ item.call.isSpeechNeeded }}</strong></div>
        <div v-if="item.call.speechLanguages.length || item.call.speechLanguagesComment">{{ $t('call.speechLanguages') }}: <strong>{{ item.call.speechLanguages.join(', ') }}</strong></div>
        <div v-if="item.call.speechLanguagesComment">{{ $t('comment') }}: <strong>{{ item.call.speechLanguagesComment }}</strong></div>
        <div v-if="item.call.speechChannelsCount">{{ $t('call.speechChannelsCount') }}: <strong>{{ item.call.speechChannelsCount }}</strong></div>
        <div v-if="item.call.isSpeechBotNeeded || item.call.isSpeechBotNeededComment">{{ $t('call.isSpeechBotNeeded') }}: <strong>{{ item.call.isSpeechBotNeeded }}</strong></div>
        <div v-if="item.call.isSpeechBotNeededComment">{{ $t('comment') }}: <strong>{{ item.call.isSpeechBotNeededComment }}</strong></div>
        <div v-if="item.call.isIVRNeeded || item.call.isIVRNeededComment">{{ $t('call.isIVRNeeded') }}: <strong>{{ item.call.isIVRNeeded }}</strong></div>
        <div v-if="item.call.isIVRNeededComment">{{ $t('comment') }}: <strong>{{ item.call.isIVRNeededComment }}</strong></div>
      </div>
    </template>

    <div class="box">
      <p class="title is-6">{{ $t('questions.title') }}</p>
      <div v-if="item.questions.channelsAlreadyUsed.length">{{ $t('questions.channelsAlreadyUsed') }}: <strong>{{ item.questions.channelsAlreadyUsed.join(', ') }}</strong></div>
      <div v-if="item.questions.supposedToUse.length">{{ $t('questions.supposedToUse') }}: <strong>{{ item.questions.supposedToUse.join(', ') }}</strong></div>
      <div v-if="item.questions.isAdditionalChannelNeeded || item.questions.isAdditionalChannelNeededComment">{{ $t('questions.isAdditionalChannelNeeded') }}: <strong>{{ item.questions.isAdditionalChannelNeeded }}</strong></div>
      <div v-if="item.questions.isAdditionalChannelNeededComment">{{ $t('comment') }}: <strong>{{ item.questions.isAdditionalChannelNeededComment }}</strong></div>
      <div v-if="item.questions.messageCount">{{ $t('questions.messageCount') }}: <strong>{{ messageCountDetail.join(', ') }}</strong></div>
      <div v-if="item.questions.maxMessagesPerSecond">{{ $t('questions.maxMessagesPerSecond') }}: <strong>{{ item.questions.maxMessagesPerSecond }}</strong></div>
      <div v-if="item.questions.isBDPNNedded || item.questions.isBDPNNeddedComment">{{ $t('questions.isBDPNNedded') }}: <strong>{{ item.questions.isBDPNNedded }}</strong></div>
      <div v-if="item.questions.isBDPNNeddedComment">{{ $t('comment') }}: <strong>{{ item.questions.isBDPNNeddedComment }}</strong></div>
      <div v-if="item.questions.isDirectConnectionNedded">{{ $t('questions.isDirectConnectionNedded') }}: <strong>{{ item.questions.isDirectConnectionNedded }}</strong></div>
      <div v-if="item.questions.integrationsCount">{{ $t('questions.integrationsCount') }}: <strong>{{ item.questions.integrationsCount }}</strong></div>
      <div v-if="item.questions.protocolIntegration.length || item.questions.protocolIntegrationComment">{{ $t('questions.protocolIntegration') }}: <strong>{{ item.questions.protocolIntegration.join(', ') }}</strong></div>
      <div v-if="item.questions.protocolIntegrationComment">{{ $t('comment') }}: <strong>{{ item.questions.protocolIntegrationComment }}</strong></div>
      <div v-if="item.questions.sourceSystemsIntegration">{{ $t('questions.sourceSystemsIntegration') }}: <strong>{{ item.questions.sourceSystemsIntegration }}</strong></div>
      <div v-if="item.questions.isCustomProtocolsIntegrationNeeded.length || item.questions.isCustomProtocolsIntegrationNeededComment">{{ $t('questions.isCustomProtocolsIntegrationNeeded') }}: <strong>{{ item.questions.isCustomProtocolsIntegrationNeeded.join('; ') }}</strong></div>
      <div v-if="item.questions.isCustomProtocolsIntegrationNeededComment">{{ $t('comment') }}: <strong>{{ item.questions.isCustomProtocolsIntegrationNeededComment }}</strong></div>
      <div v-if="item.questions.importExportRequired">{{ $t('questions.importExportRequired') }}: <strong>{{ item.questions.importExportRequired }}</strong></div>
      <div v-if="item.questions.dataCenterCount">{{ $t('questions.dataCenterCount') }}: <strong>{{ $t(item.questions.dataCenterCount) }}</strong></div>
      <div v-if="item.questions.dcReservation || item.questions.dcReservationComment">{{ $t('questions.dcReservation') }}: <strong>{{ item.questions.dcReservation }}</strong></div>
      <div v-if="item.questions.dcReservationComment">{{ $t('comment') }}: <strong>{{ item.questions.dcReservationComment }}</strong></div>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('support.title') }}</p>
      <div v-if="item.support.supportTime || item.support.supportTimeComment">{{ $t('support.supportTime') }}: <strong>{{ item.support.supportTime }}</strong></div>
      <div v-if="item.support.supportTimeComment">{{ $t('comment') }}: <strong>{{ item.support.supportTimeComment }}</strong></div>
      <div v-if="item.support.sla">{{ $t('support.sla') }}: <strong>{{ item.support.sla }}</strong></div>
      <div v-if="item.support.contoursCount.length || item.support.contoursCountComment">{{ $t('support.contoursCount') }}: <strong>{{ item.support.contoursCount.join(', ') }}</strong></div>
      <div v-if="item.support.contoursCountComment">{{ $t('comment') }}: <strong>{{ item.support.contoursCountComment }}</strong></div>
      <div v-if="item.support.remoteAccess || item.support.remoteAccessComment">{{ $t('support.remoteAccess') }}: <strong>{{ item.support.remoteAccess }}</strong></div>
      <div v-if="item.support.remoteAccessComment">{{ $t('comment') }}: <strong>{{ item.support.remoteAccessComment }}</strong></div>
    </div>

    <div class="box" v-if="item.extendedSupport">
      <p class="title is-6">{{ $t('extendedSupport.title') }}</p>
      <div v-if="item.extendedSupport.supportThirdParty || item.extendedSupport.supportThirdPartyComment">{{ $t('extendedSupport.supportThirdParty') }}: <strong>{{ item.extendedSupport.supportThirdParty }}</strong></div>
      <div v-if="item.extendedSupport.supportThirdPartyComment">{{ $t('comment') }}: <strong>{{ item.extendedSupport.supportThirdPartyComment }}</strong></div>
      <div v-if="item.extendedSupport.isMonitoringNeeded || item.extendedSupport.isMonitoringNeededComment">{{ $t('extendedSupport.isMonitoringNeeded') }}: <strong>{{ item.extendedSupport.isMonitoringNeeded }}</strong></div>
      <div v-if="item.extendedSupport.isMonitoringNeededComment">{{ $t('comment') }}: <strong>{{ item.extendedSupport.isMonitoringNeededComment }}</strong></div>
      <div v-if="item.extendedSupport.isAlertReactionNeeded || item.extendedSupport.isAlertReactionNeededComment">{{ $t('extendedSupport.isAlertReactionNeeded') }}: <strong>{{ item.extendedSupport.isAlertReactionNeeded }}</strong></div>
      <div v-if="item.extendedSupport.isAlertReactionNeededComment">{{ $t('comment') }}: <strong>{{ item.extendedSupport.isAlertReactionNeededComment }}</strong></div>
      <div v-if="item.extendedSupport.isProActiveMonitoringNeeded || item.extendedSupport.isProActiveMonitoringNeededComment">{{ $t('extendedSupport.isProActiveMonitoringNeeded') }}: <strong>{{ item.extendedSupport.isProActiveMonitoringNeeded }}</strong></div>
      <div v-if="item.extendedSupport.isProActiveMonitoringNeededComment">{{ $t('comment') }}: <strong>{{ item.extendedSupport.isProActiveMonitoringNeededComment }}</strong></div>
      <div v-if="item.extendedSupport.isInstallUpdateNeeded || item.extendedSupport.isInstallUpdateNeededComment">{{ $t('extendedSupport.isInstallUpdateNeeded') }}: <strong>{{ item.extendedSupport.isInstallUpdateNeeded }}</strong></div>
      <div v-if="item.extendedSupport.isInstallUpdateNeededComment">{{ $t('comment') }}: <strong>{{ item.extendedSupport.isInstallUpdateNeededComment }}</strong></div>
      <div v-if="item.extendedSupport.additionalComment">{{ $t('extendedSupport.additionalComment') }}: <strong>{{ item.extendedSupport.additionalComment }}</strong></div>
      <div v-if="item.extendedSupport.usabilityRate">{{ $t('extendedSupport.usabilityRate') }}: <strong>{{ item.extendedSupport.usabilityRate }}</strong></div>
      <div v-if="item.extendedSupport.whatToAdd">{{ $t('extendedSupport.whatToAdd') }}: <strong>{{ item.extendedSupport.whatToAdd }}</strong></div>
    </div>

    </template>

    <footer-component/>
  </div>
</template>

<script>
import axios from "axios"
import NavbarComponent from '@/components/admin/navbarComponent.vue'
import FooterComponent from '@/components/admin/footerComponent.vue'

export default {
  name: 'AdminDetailView',
  components: {
    NavbarComponent,
    FooterComponent,
  },
  data() {
    return {
      item: null,
    }
  },
  created() {
    this.getItem(this.$route.params.id)
  },
  computed: {
    messageCountDetail() {
      if (!this.item) return []
      let res = []
      let total = 0
      Object.keys(this.item.questions.messageCount).forEach((key) => {
        res.push(key + ' ' + this.item.questions.messageCount[key])
        total += Number(this.item.questions.messageCount[key].replace('>', '').replaceAll(' ', ''))
      })
      res.push('Всего от ' + new Intl.NumberFormat("ru").format(total))
      return res
    }
  },
  methods: {
    async getItem(id) {
      const res = await axios.get('/api/answers/' + id)
      this.item = res.data
    },
    print() {
      window.print();
      return false;
    }
  }
}
</script>

<style scoped>
@media print {
	.noprint {
		display: none;
	}
}
</style>