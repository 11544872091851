<template>
  <div>
    <div class="card mb-5">
      <header class="card-header">
        <p class="card-header-title">
          {{ $t('support.title') }}
        </p>
      </header>
      <div class="card-content">
        <p class="title is-6">{{ $t('support.supportTime') }}</p>
        <b-field>
          <b-radio v-model="form.support.supportTime" native-value="8x5">
            8x5
          </b-radio>
        </b-field>
        <b-field>
          <b-radio v-model="form.support.supportTime" native-value="24x7">
            24x7
          </b-radio>
        </b-field>
        <b-field :label="$t('comment')">
          <b-input-auto v-model="form.support.supportTimeComment" placeholder=""/>
        </b-field>
      </div>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('support.sla') }}</p>
      <div class="mb-5">{{ $t('support.slaDescription') }}</div>
      <b-field>
        <b-input-auto v-model="form.support.sla" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('support.contoursCount') }}</p>
      <div class="mb-5"></div>
      <b-field>
        <b-checkbox v-model="form.support.contoursCount" native-value="test">
          test
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.support.contoursCount" native-value="prod">
          prod
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.support.contoursCount" native-value="preprod">
          preprod
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.support.contoursCount" :native-value="$t('other')">
          {{ $t('other') }}
        </b-checkbox>
      </b-field>
      <b-field>
        <b-input-auto v-model="form.support.contoursCountComment" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('support.remoteAccess') }}</p>
      <b-field>
        <b-radio-button v-model="form.support.remoteAccess" :native-value="$t('yes')" type="is-primary">
          <b-icon icon="check"></b-icon>
          <span>{{ $t('yes') }}</span>
        </b-radio-button>
        <b-radio-button v-model="form.support.remoteAccess" :native-value="$t('no')" type="is-primary">
          <b-icon icon="close"></b-icon>
          <span>{{ $t('no') }}</span>
        </b-radio-button>
      </b-field>
      <b-field :label="$t('comment')">
        <b-input-auto v-model="form.support.remoteAccessComment" placeholder=""/>
      </b-field>
    </div>

    <div class="mt-5 mb-5">
      <div class="mt-5 mb-5 is-size-7">* {{ $t('requiredField') }}</div>

      <b-button class="mr-5" @click="$emit('back')">{{ $t('back') }}</b-button>
      <b-button class="mr-5" @click="$emit('next')">{{ $t('next') }}</b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import bInputAuto from '@/components/bInputAuto.vue'

export default {
  components: {
    bInputAuto
  },
  computed: mapState([
    'form'
  ]),
}
</script>
