<template>
  <ValidationObserver ref="validator" v-slot="{ errors }">
  <section id="login" class="hero is-fullheight has-text-white background-wings">
    <div class="hero-body ">
      <div class="container has-text-centered">
        <div class="columns">
          <div class="column is-6-desktop is-offset-3-desktop is-12-mobile is-offset-0-mobile">
            <h3 class="title">{{ $t('title') }}</h3>
            <div class="box">
              <ValidationProvider vid="login" name="Логин" rules="max:50|required" slim>
                <b-field :type="errors.login && errors.login.length ? 'is-danger' : ''" :message="errors.login">
                  <b-input type="text" v-model.trim="credentials.login" placeholder="Логин"></b-input>
                </b-field>
              </ValidationProvider>
              <ValidationProvider vid="pass" name="Пароль" rules="min:5|max:50|required" slim>
                <b-field :type="errors.pass && errors.pass.length ? 'is-danger' : ''" :message="errors.pass">
                  <b-input type="password" v-model="credentials.pass" placeholder="Пароль"></b-input>
                </b-field>
              </ValidationProvider>
              <b-button :disabled="!credentials.login || !credentials.pass" @click="login()" expanded outlined type="">Войти</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'LoginView',
  data() {
    return {
      credentials: {
        login: '',
        pass: ''
      },
    }
  },
  created() {
  },
  methods: {
    async login() {
      await this.$store.dispatch('auth', this.credentials)
      this.$router.push(this.$route.query.redirect || '/admin')
    }
  }
}
</script>
