<template>
  <div class="container container-form">
    <div class="pt-3 mb-3 is-flex is-justify-content-right">
      <b-select placeholder="RU/EN" icon="earth" :value="$i18n.locale" @input="setLocale">
        <option value="ru">Русский</option>
        <option value="en">English</option>
      </b-select>
    </div>
    <div class="box box-logo"></div>
    <div class="box box-title">
      <h1 class="title">{{ $t('title')}}</h1>
      {{ $t('description') }} «<a :href="$t('url.product')" target="_blank">{{ $t('productName') }}</a>»
    </div>

    <first-page v-if="page == 1" @next="next" @clear="clear" :showClear="showClear"/>
    <sms-page v-if="page == 2" @next="next" @back="back"/>
    <push-page v-if="page == 3" @next="next" @back="back"/>
    <call-page v-if="page == 4" @next="next" @back="back"/>
    <common-questions-page v-if="page == 5" @next="next" @back="back"/>
    <support-page v-if="page == 6" @next="next" @back="back"/>
    <extended-support-page v-if="page == 7" @back="back" @submit="submit"/>

    <b-loading v-model="isLoading"></b-loading>

    <div v-if="page == 'success'" class="box">
      {{ $t('congratulations') }}
    </div>

    <footer-component/>
  </div>
</template>

<script>
import {cloneDeep, isEqual} from "lodash"
import {localize} from "vee-validate";
import axios from "axios"
import FooterComponent from '@/components/footerComponent.vue'
import FirstPage from '@/components/FirstPage.vue'
import SmsPage from '@/components/SmsPage.vue'
import PushPage from '@/components/PushPage.vue'
import CallPage from '@/components/CallPage.vue'
import CommonQuestionsPage from '@/components/CommonQuestionsPage.vue'
import SupportPage from '@/components/SupportPage.vue'
import ExtendedSupportPage from '@/components/ExtendedSupportPage.vue'

export default {
  name: 'HomeView',
  components: {
    FooterComponent,
    FirstPage,
    SmsPage,
    PushPage,
    CallPage,
    CommonQuestionsPage,
    SupportPage,
    ExtendedSupportPage,
  },
  data() {
    return {
      page: 1,
      lastPage: 7,
      initialState: undefined,
      isLoading: false,
    }
  },
  created() {
    window.addEventListener("beforeunload", this.leaving)
    this.initialState = cloneDeep(this.$store.state)
    if (localStorage.getItem('formData')) {
      this.$store.replaceState(JSON.parse(localStorage.getItem('formData')))
    }
    this.getLocale()
  },
  computed:{
    showClear() {
      return !isEqual(this.initialState, this.$store.state)
    }
  },
  methods: {
    leaving() {
      if (this.showClear)
        localStorage.setItem('formData', JSON.stringify(this.$store.state))
    },
    clear() {
      this.$store.replaceState(cloneDeep(this.initialState))
      localStorage.removeItem('formData')
    },
    next() {
      if (this.page < this.lastPage) this.page++
      window.scrollTo({ top: 0, behavior: 'auto' })
    },
    back() {
      if (this.page > 1) this.page--
    },
    async submit() {
      try {
        this.isLoading = true
        await axios.post('/api/answers', this.$store.state.form)
        this.isLoading = false
        this.page = 'success'
      } catch(err) {
        this.isLoading = false
        let msg = err.message
        if (err.response?.data?.errors?.length) {
          msg = err.response.data.errors.map((item) => item.msg).join(', ')
        }
        this.alertCustomError(msg)
      }
    },
    alertCustomError(msg = this.$t('errorOccured')) {
      this.$buefy.dialog.alert({
        title: this.$t('error'),
        message: msg,
        type: 'is-danger',
        hasIcon: true,
        icon: 'close-circle-outline',
        iconPack: 'mdi',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    setLocale($event) {
      this.$i18n.locale = $event;
      localize($event)
      localStorage.setItem('locale', $event)
    },
    getLocale() {
      let language = localStorage.getItem('locale')
      if (!language) {
        language = window.navigator ? window.navigator.language : "ru"
        language = language.substr(0, 2).toLowerCase()
        localStorage.setItem('locale', language)
      }
      this.$i18n.locale = language
      localize(language)
    },
  }
}
</script>
