import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/main.scss'
import Buefy from 'buefy'
import "@/plugins/veeValidate"
import i18n from './i18n'
import axios from "axios";

Vue.use(Buefy)

Vue.config.productionTip = false

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = token;
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
