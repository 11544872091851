<template>
  <b-navbar>
    <!-- <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/admin' }">
        <img src="/logo.png" alt="Лого">
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/admin' }">
        Ответы
      </b-navbar-item>
    </template> -->

    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <a class="button is-light" @click="logout()">
            Выйти
          </a>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$router.push({name: 'login'});
    }
  }
}
</script>
