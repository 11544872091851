<template>
  <div>
    <div class="card mb-5">
      <header class="card-header">
        <p class="card-header-title">
          {{ $t('call.title') }}
        </p>
      </header>
      <div class="card-content">
        <p class="title is-6">{{ $t('call.isCallNeeded') }} *</p>
        <b-field>
          <b-radio-button v-model="form.call.isCallNeeded" :native-value="true" type="is-primary" @input="onChange">
            <b-icon icon="check"></b-icon>
            <span>{{ $t('yes') }}</span>
          </b-radio-button>
          <b-radio-button v-model="form.call.isCallNeeded" :native-value="false" type="is-primary" @input="onChange">
            <b-icon icon="close"></b-icon>
            <span>{{ $t('no') }}</span>
          </b-radio-button>
        </b-field>
      </div>
    </div>

    <template v-if="form.call.isCallNeeded">
      <ValidationObserver ref="validator" v-slot="{ errors }">
      <div class="box">
        <p class="title is-6">{{ $t('call.isFlashCallNeeded') }}</p>
        <div class="mb-5">{{ $t('call.isFlashCallNeededDescription') }}</div>
        <b-field>
          <b-radio v-model="form.call.isFlashCallNeeded" :native-value="$t('no')">
            {{ $t('no') }}
          </b-radio>
        </b-field>
        <b-field>
          <b-radio v-model="form.call.isFlashCallNeeded" :native-value="$t('call.isFlashCallNeededValues.yesLast4digits')">
            {{ $t('call.isFlashCallNeededValues.yesLast4digits') }}
          </b-radio>
        </b-field>
        <b-field>
          <b-radio v-model="form.call.isFlashCallNeeded" :native-value="$t('call.isFlashCallNeededValues.yesLast5digits')">
            {{ $t('call.isFlashCallNeededValues.yesLast5digits') }}
          </b-radio>
        </b-field>
        <b-field>
          <b-radio v-model="form.call.isFlashCallNeeded" :native-value="$t('call.isFlashCallNeededValues.yesLast6digits')">
            {{ $t('call.isFlashCallNeededValues.yesLast6digits') }}
          </b-radio>
        </b-field>
        <b-field>
          <b-radio v-model="form.call.isFlashCallNeeded" :native-value="$t('call.isFlashCallNeededValues.yesSpeechSynthesis')">
            {{ $t('call.isFlashCallNeededValues.yesSpeechSynthesis') }}
          </b-radio>
        </b-field>
        <b-field>
          <b-radio v-model="form.call.isFlashCallNeeded" :native-value="$t('other')">
            {{ $t('other') }}
          </b-radio>
        </b-field>
        <b-field>
          <b-input-auto v-model="form.call.isFlashCallNeededComment" placeholder=""/>
        </b-field>
      </div>

      <div class="box">
        <b-field :label="$t('call.simultaneousCalls')">
          <b-input type="text" v-model="form.call.simultaneousCalls" placeholder=""></b-input>
        </b-field>
      </div>

      <div class="box">
        <b-field :label="$t('call.callDuration')">
          <b-input type="text" v-model="form.call.callDuration" placeholder=""></b-input>
        </b-field>
      </div>

      <div class="box">
        <p class="title is-6">{{ $t('call.isIncomingCallsNeeded') }}</p>
        <b-field>
          <b-radio-button v-model="form.call.isIncomingCallsNeeded" :native-value="$t('yes')" type="is-primary">
            <b-icon icon="check"></b-icon>
            <span>{{ $t('yes') }}</span>
          </b-radio-button>
          <b-radio-button v-model="form.call.isIncomingCallsNeeded" :native-value="$t('no')" type="is-primary">
            <b-icon icon="close"></b-icon>
            <span>{{ $t('no') }}</span>
          </b-radio-button>
        </b-field>
        <b-field :label="$t('comment')">
          <b-input-auto v-model="form.call.isIncomingCallsNeededComment" placeholder=""/>
        </b-field>
      </div>

      <div class="box">
        <p class="title is-6">{{ $t('call.internetProvider') }}</p>
        <div class="mb-5"></div>
        <b-field>
          <b-radio v-model="form.call.internetProvider" :native-value="$t('call.internetProviderValues.no')">
            {{ $t('call.internetProviderValues.no') }}
          </b-radio>
        </b-field>
        <b-field>
          <b-radio v-model="form.call.internetProvider" :native-value="$t('call.internetProviderValues.yes')">
            {{ $t('call.internetProviderValues.yes') }}
          </b-radio>
        </b-field>
        <b-field>
          <b-input-auto v-model="form.call.internetProviderComment" placeholder=""/>
        </b-field>
      </div>

      <div class="box">
        <p class="title is-6">{{ $t('call.callProtocol') }}</p>
        <b-field>
          <b-radio v-model="form.call.callProtocol" native-value="SIP">
            SIP
          </b-radio>
        </b-field>
        <b-field>
          <b-radio v-model="form.call.callProtocol" native-value="H.323">
            H.323
          </b-radio>
        </b-field>
        <b-field>
          <b-radio v-model="form.call.callProtocol" native-value="SS7">
            SS7
          </b-radio>
        </b-field>
        <b-field :label="$t('comment')">
          <b-input-auto v-model="form.call.callProtocolComment" placeholder=""/>
        </b-field>
      </div>

      <div class="box">
        <p class="title is-6">{{ $t('call.isSpeechNeeded') }} *</p>
        <div class="mb-5">{{ $t('call.isSpeechNeededDescription') }}</div>
        <ValidationProvider vid="isSpeechNeeded" :name="$t('call.isSpeechNeeded')" rules="required">
        <b-field :type="errors.isSpeechNeeded && errors.isSpeechNeeded.length ? 'is-danger' : ''" :message="errors.isSpeechNeeded">
          <b-select v-model="form.call.isSpeechNeeded" placeholder="">
            <option :value="$t('no')">{{ $t('no') }}</option>
            <option :value="$t('call.isSpeechNeededValues.noAlreadyRecorded')">{{ $t('call.isSpeechNeededValues.noAlreadyRecorded') }}</option>
            <option :value="$t('call.isSpeechNeededValues.yesNeedSythesis')">{{ $t('call.isSpeechNeededValues.yesNeedSythesis') }}</option>
            <option :value="$t('call.isSpeechNeededValues.yesNeedRecognition')">{{ $t('call.isSpeechNeededValues.yesNeedRecognition') }}</option>
            <option :value="$t('call.isSpeechNeededValues.yesNeedBoth')">{{ $t('call.isSpeechNeededValues.yesNeedBoth') }}</option>
          </b-select>
        </b-field>
        </ValidationProvider>
      </div>

      <template v-if="form.call.isSpeechNeeded && (form.call.isSpeechNeeded.startsWith('Да') || form.call.isSpeechNeeded.startsWith('Yes'))">

        <div class="box">
          <p class="title is-6">{{ $t('call.speechLanguages') }}</p>
          <div class="mb-5"></div>
          <b-field>
            <b-checkbox v-model="form.call.speechLanguages" :native-value="$t('russian')">
              {{ $t('russian') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox v-model="form.call.speechLanguages" :native-value="$t('english')">
              {{ $t('english') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox v-model="form.call.speechLanguages" :native-value="$t('other')">
              {{ $t('other') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-input type="text" v-model="form.call.speechLanguagesComment" placeholder=""></b-input>
          </b-field>
        </div>

        <div class="box">
          <b-field :label="$t('call.speechChannelsCount')">
            <b-input type="text" v-model="form.call.speechChannelsCount" placeholder=""></b-input>
          </b-field>
        </div>

        <div class="box">
          <p class="title is-6">{{ $t('call.isSpeechBotNeeded') }}</p>
          <b-field>
            <b-radio v-model="form.call.isSpeechBotNeeded" :native-value="$t('yes')">
              {{ $t('yes') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.call.isSpeechBotNeeded" :native-value="$t('no')">
              {{ $t('no') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.call.isSpeechBotNeeded" :native-value="$t('other')">
              {{ $t('other') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-input-auto v-model="form.call.isSpeechBotNeededComment" placeholder=""/>
          </b-field>
        </div>

        <div class="box">
          <p class="title is-6">{{ $t('call.isIVRNeeded') }}</p>
          <b-field>
            <b-radio v-model="form.call.isIVRNeeded" :native-value="$t('no')">
              {{ $t('no') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.call.isIVRNeeded" :native-value="$t('call.isIVRNeededValues.yesVoiceControl')">
              {{ $t('call.isIVRNeededValues.yesVoiceControl') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.call.isIVRNeeded" :native-value="$t('call.isIVRNeededValues.yesTouchControl')">
              {{ $t('call.isIVRNeededValues.yesTouchControl') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.call.isIVRNeeded" :native-value="$t('other')">
              {{ $t('other') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-input-auto v-model="form.call.isIVRNeededComment" placeholder=""/>
          </b-field>
        </div>

      </template>
      </ValidationObserver>
    </template>

    <div class="mt-5 mb-5">
      <div class="mt-5 mb-5 is-size-7">* {{ $t('requiredField') }}</div>

      <b-button class="mr-5" @click="$emit('back')">{{ $t('back') }}</b-button>
      <b-button class="mr-5" @click="next()">{{ $t('next') }}</b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import bInputAuto from '@/components/bInputAuto.vue'

export default {
  computed: mapState([
    'form'
  ]),
  components: {
    bInputAuto
  },
  created() {
    this.onChange(this.form.call.isCallNeeded)
  },
  methods: {
    onChange(val) {
      const index = this.form.questions.supposedToUse.indexOf('Call')
      if (val) {
        if (index === -1) {
          this.form.questions.supposedToUse.push('Call')
        }
      } else {
        if (index > -1) {
          this.form.questions.supposedToUse.splice(index, 1)
        }
      }
    },
    async next() {
      if (this.$refs.validator && !await this.$refs.validator.validate()) {
        // console.log(this.$refs.validator.errors)
        return
      }
      this.$emit('next')
    }
  }
}
</script>
