import Vue from 'vue'
import VueRouter from 'vue-router'

import {guard} from "@/router/access";
import HomePage from '@/views/HomeView.vue'
import AdminLayout from '@/views/AdminLayout.vue'
import LoginPage from '@/views/LoginView.vue'
import AdminPage from '@/views/AdminView.vue'
import AdminDetailPage from '@/views/AdminDetailView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/admin',
    name: '',
    component: AdminLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginPage,
        beforeEnter: guard
      },
      {
        path: '/',
        name: 'admin',
        component: AdminPage,
        beforeEnter: guard
      },
      {
        path: 'detail/:id',
        name: 'detail',
        component: AdminDetailPage,
        beforeEnter: guard
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
