<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: 'HomeView',
  components: {
  },
  created() {
    axios.interceptors.response.use(
      (response) => {
        // this.$store.commit('setLoading', false)
        return response;
      },
      async (error) => {
        // this.$store.commit('setLoading', false)
        if (error.response && error.response.status === 401) {
          await this.$store.dispatch("logout")
          this.$router.push({name: "login"})
        }
        throw error;
      }
    )
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 100vh;
}
body {
  background-color: #e6f3f9;
}
.container-form {
  max-width: 650px !important;
}
.footer {
  background-color: #e6f3f9 !important;
}
.box-logo {
  background-image: url(assets/logo.png);
  background-size: cover;
  background-position: center;
  height: 160px;
  max-height: 22vw;
}
.box-title {
  border-top: 10px solid #5baed5;
}
.card-header {
  background-color: #5baed5 !important;
}
.card-header-title {
  color: #fff !important;
}
.fr {
  float: right;
}
</style>
