<template>
  <div>
    <div class="card mb-5">
      <header class="card-header">
        <p class="card-header-title">
          {{ $t('questions.title') }}
        </p>
      </header>
      <div class="card-content">
        <p class="title is-6">{{ $t('questions.channelsToUse') }}</p>
        <div class="columns is-mobile has-text-centered">
          <div class="column"></div>
          <div class="column">
            {{ $t('questions.channelsAlreadyUsed') }}
          </div>
          <div class="column">
            {{ $t('questions.supposedToUse') }}
          </div>
        </div>
        <div class="columns is-mobile has-text-centered" v-if="form.sms.isSmsNeeded">
          <div class="column has-text-left">SMS</div>
          <div class="column">
            <b-checkbox v-model="form.questions.channelsAlreadyUsed" native-value="SMS"/>
          </div>
          <div class="column">
            <b-checkbox v-model="form.questions.supposedToUse" native-value="SMS" disabled/>
          </div>
        </div>
        <div class="columns is-mobile has-text-centered" v-if="form.push.isPushNeeded">
          <div class="column has-text-left">Push</div>
          <div class="column">
            <b-checkbox v-model="form.questions.channelsAlreadyUsed" native-value="Push"/>
          </div>
          <div class="column">
            <b-checkbox v-model="form.questions.supposedToUse" native-value="Push" disabled/>
          </div>
        </div>
        <div class="columns is-mobile has-text-centered" v-if="form.call.isCallNeeded">
          <div class="column has-text-left">{{ $t('calls') }}</div>
          <div class="column">
            <b-checkbox v-model="form.questions.channelsAlreadyUsed" native-value="Call"/>
          </div>
          <div class="column">
            <b-checkbox v-model="form.questions.supposedToUse" native-value="Call" disabled />
          </div>
        </div>
        <div class="columns is-mobile has-text-centered">
          <div class="column has-text-left">Email</div>
          <div class="column">
            <b-checkbox v-model="form.questions.channelsAlreadyUsed" native-value="Email"/>
          </div>
          <div class="column">
            <b-checkbox v-model="form.questions.supposedToUse" native-value="Email"/>
          </div>
        </div>
        <div class="columns is-mobile has-text-centered">
          <div class="column has-text-left">Viber</div>
          <div class="column">
            <b-checkbox v-model="form.questions.channelsAlreadyUsed" native-value="Viber"/>
          </div>
          <div class="column">
            <b-checkbox v-model="form.questions.supposedToUse" native-value="Viber"/>
          </div>
        </div>
        <div class="columns is-mobile has-text-centered">
          <div class="column has-text-left">WhatsApp</div>
          <div class="column">
            <b-checkbox v-model="form.questions.channelsAlreadyUsed" native-value="WhatsApp"/>
          </div>
          <div class="column">
            <b-checkbox v-model="form.questions.supposedToUse" native-value="WhatsApp"/>
          </div>
        </div>
        <div class="columns is-mobile has-text-centered">
          <div class="column has-text-left">VK</div>
          <div class="column">
            <b-checkbox v-model="form.questions.channelsAlreadyUsed" native-value="VK"/>
          </div>
          <div class="column">
            <b-checkbox v-model="form.questions.supposedToUse" native-value="VK"/>
          </div>
        </div>
        <div class="columns is-mobile has-text-centered">
          <div class="column has-text-left">OK</div>
          <div class="column">
            <b-checkbox v-model="form.questions.channelsAlreadyUsed" native-value="OK"/>
          </div>
          <div class="column">
            <b-checkbox v-model="form.questions.supposedToUse" native-value="OK"/>
          </div>
        </div>
        <div class="columns is-mobile has-text-centered">
          <div class="column has-text-left">USSD</div>
          <div class="column">
            <b-checkbox v-model="form.questions.channelsAlreadyUsed" native-value="USSD"/>
          </div>
          <div class="column">
            <b-checkbox v-model="form.questions.supposedToUse" native-value="USSD"/>
          </div>
        </div>
        <div class="columns is-mobile has-text-centered">
          <div class="column has-text-left">{{ $t('webPush') }}</div>
          <div class="column">
            <b-checkbox v-model="form.questions.channelsAlreadyUsed" native-value="WebPush"/>
          </div>
          <div class="column">
            <b-checkbox v-model="form.questions.supposedToUse" native-value="WebPush"/>
          </div>
        </div>
        <!-- <p> {{ channelsAlreadyUsed }}, {{ supposedToUse }} </p> -->
      </div>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.isAdditionalChannelNeeded') }}</p>
      <div class="mb-5"></div>
      <b-field>
        <b-radio-button v-model="form.questions.isAdditionalChannelNeeded" :native-value="$t('yes')" type="is-primary">
          <b-icon icon="check"></b-icon>
          <span>{{ $t('yes') }}</span>
        </b-radio-button>
        <b-radio-button v-model="form.questions.isAdditionalChannelNeeded" :native-value="$t('no')" type="is-primary">
          <b-icon icon="close"></b-icon>
          <span>{{ $t('no') }}</span>
        </b-radio-button>
      </b-field>
      <b-field :label="$t('comment')">
        <b-input-auto v-model="form.questions.isAdditionalChannelNeededComment" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.messageCount') }}</p>
      <div class="mb-5"></div>
      <b-field horizontal label="SMS" v-if="form.sms.isSmsNeeded">
          <b-autocomplete
              v-model="form.questions.messageCount.sms"
              :data="messageCountData"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
      <b-field horizontal label="Email" v-if="form.questions.supposedToUse.includes('Email')">
          <b-autocomplete
              v-model="form.questions.messageCount.email"
              :data="messageCountData"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
      <b-field horizontal label="Viber" v-if="form.questions.supposedToUse.includes('Viber')">
          <b-autocomplete
              v-model="form.questions.messageCount.viber"
              :data="messageCountData"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
      <b-field horizontal label="WhatsApp" v-if="form.questions.supposedToUse.includes('WhatsApp')">
          <b-autocomplete
              v-model="form.questions.messageCount.whatsapp"
              :data="messageCountData"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
      <b-field horizontal label="VK" v-if="form.questions.supposedToUse.includes('VK')">
          <b-autocomplete
              v-model="form.questions.messageCount.vk"
              :data="messageCountData"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
      <b-field horizontal label="OK" v-if="form.questions.supposedToUse.includes('OK')">
          <b-autocomplete
              v-model="form.questions.messageCount.ok"
              :data="messageCountData"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
      <b-field horizontal label="USSD" v-if="form.questions.supposedToUse.includes('USSD')">
          <b-autocomplete
              v-model="form.questions.messageCount.ussd"
              :data="messageCountData"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
      <b-field horizontal :label="$t('calls')" v-if="form.call.isCallNeeded">
          <b-autocomplete
              v-model="form.questions.messageCount.call"
              :data="messageCountData"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
      <b-field horizontal :label="$t('webPush')" v-if="form.questions.supposedToUse.includes('WebPush')">
          <b-autocomplete
              v-model="form.questions.messageCount.webpush"
              :data="messageCountData"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
      <b-field horizontal :label="$t('mobilePush')" v-if="form.push.isPushNeeded">
          <b-autocomplete
              v-model="form.questions.messageCount.push"
              :data="messageCountData"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
      <b-field horizontal :label="$t('other')"  v-if="form.questions.isAdditionalChannelNeededComment">
          <b-autocomplete
              v-model="form.questions.messageCount.other"
              :data="messageCountData"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
      <!-- <p>{{ messageCount }}</p> -->
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.maxMessagesPerSecond') }}</p>
      <div class="mb-5">{{ $t('questions.maxMessagesPerSecondDescription') }}</div>
      <b-field label="">
        <b-autocomplete
              v-model="form.questions.maxMessagesPerSecond"
              :data="['50', '100', '500', '1000']"
              :open-on-focus="true"
              :clearable="true"
          />
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.isBDPNNedded') }}</p>
      <div class="mb-5">{{ $t('questions.isBDPNNeddedDescription') }}</div>
      <b-field>
        <b-radio-button v-model="form.questions.isBDPNNedded" :native-value="$t('yes')" type="is-primary">
          <b-icon icon="check"></b-icon>
          <span>{{ $t('yes') }}</span>
        </b-radio-button>
        <b-radio-button v-model="form.questions.isBDPNNedded" :native-value="$t('no')" type="is-primary">
          <b-icon icon="close"></b-icon>
          <span>{{ $t('no') }}</span>
        </b-radio-button>
      </b-field>
      <b-field :label="$t('comment')">
        <b-input-auto v-model="form.questions.isBDPNNeddedComment" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.isDirectConnectionNedded') }}</p>
      <div class="mb-5"></div>
      <b-field>
        <b-radio-button v-model="form.questions.isDirectConnectionNedded" :native-value="$t('yes')" type="is-primary">
          <b-icon icon="check"></b-icon>
          <span>{{ $t('yes') }}</span>
        </b-radio-button>
        <b-radio-button v-model="form.questions.isDirectConnectionNedded" :native-value="$t('no')" type="is-primary">
          <b-icon icon="close"></b-icon>
          <span>{{ $t('no') }}</span>
        </b-radio-button>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.integrationsCount') }}</p>
      <div class="mb-5">{{ $t('questions.integrationsCountDescription') }}</div>
      <b-field>
        <b-input-auto v-model="form.questions.integrationsCount" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.protocolIntegration') }}</p>
      <div class="mb-5">{{ $t('questions.protocolIntegrationDescription') }}</div>
      <b-field>
        <b-checkbox v-model="form.questions.protocolIntegration" native-value="SMPP">
          SMPP
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.questions.protocolIntegration" native-value="HTTP">
          HTTP
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.questions.protocolIntegration" :native-value="$t('other')">
          {{ $t('other') }}
        </b-checkbox>
      </b-field>
      <b-field>
        <b-input-auto v-model="form.questions.protocolIntegrationComment" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.sourceSystemsIntegration') }}</p>
      <div class="mb-5"></div>
      <b-field label="">
        <b-input-auto v-model="form.questions.sourceSystemsIntegration" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.isCustomProtocolsIntegrationNeeded') }}</p>
      <div class="mb-5"></div>
      <b-field>
        <b-checkbox v-model="form.questions.isCustomProtocolsIntegrationNeeded" :native-value="$t('questions.isCustomProtocolsIntegrationNeededValues.noRest')">
          {{ $t('questions.isCustomProtocolsIntegrationNeededValues.noRest') }}
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.questions.isCustomProtocolsIntegrationNeeded" :native-value="$t('questions.isCustomProtocolsIntegrationNeededValues.yesHTTP')">
          {{ $t('questions.isCustomProtocolsIntegrationNeededValues.yesHTTP') }}
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.questions.isCustomProtocolsIntegrationNeeded" :native-value="$t('questions.isCustomProtocolsIntegrationNeededValues.yesSMPP')">
          {{ $t('questions.isCustomProtocolsIntegrationNeededValues.yesSMPP') }}
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.questions.isCustomProtocolsIntegrationNeeded" :native-value="$t('questions.isCustomProtocolsIntegrationNeededValues.yesTables')">
          {{ $t('questions.isCustomProtocolsIntegrationNeededValues.yesTables') }}
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.questions.isCustomProtocolsIntegrationNeeded" :native-value="$t('questions.isCustomProtocolsIntegrationNeededValues.yesFileInterface')">
          {{ $t('questions.isCustomProtocolsIntegrationNeededValues.yesFileInterface') }}
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.questions.isCustomProtocolsIntegrationNeeded" :native-value="$t('questions.isCustomProtocolsIntegrationNeededValues.yesEmail')">
          {{ $t('questions.isCustomProtocolsIntegrationNeededValues.yesEmail') }}
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.questions.isCustomProtocolsIntegrationNeeded" :native-value="$t('questions.isCustomProtocolsIntegrationNeededValues.yesQueues')">
          {{ $t('questions.isCustomProtocolsIntegrationNeededValues.yesQueues') }}
        </b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="form.questions.isCustomProtocolsIntegrationNeeded" :native-value="$t('other')">
          {{ $t('other') }}
        </b-checkbox>
      </b-field>
      <b-field>
        <b-input-auto v-model="form.questions.isCustomProtocolsIntegrationNeededComment" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.importExportRequired') }}</p>
      <div class="mb-5">{{ $t('questions.importExportRequiredDescription') }}</div>
      <b-field label="">
        <b-input-auto v-model="form.questions.importExportRequired" placeholder=""/>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.dbmsUsed') }}</p>
      <div class="mb-5"></div>
      <b-field>
        <b-radio v-model="form.questions.dbmsUsed" :native-value="'Oracle'">
          Oracle
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="form.questions.dbmsUsed" :native-value="'PostgreSQL'">
          PostgreSQL
        </b-radio>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.dataCenterCount') }} *</p>
      <b-field>
        <b-radio v-model="form.questions.dataCenterCount" native-value="one">
          {{ $t('one') }}
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="form.questions.dataCenterCount" native-value="multiple">
          {{ $t('multiple') }}
        </b-radio>
      </b-field>
    </div>

    <div class="box">
      <p class="title is-6">{{ $t('questions.dcReservation') }}</p>
      <div class="mb-5">{{ $t('questions.dcReservationDescription') }}</div>
      <b-field>
        <b-radio v-model="form.questions.dcReservation" native-value="active-active">
          active-active
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="form.questions.dcReservation" native-value="active-standby">
          active-standby
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="form.questions.dcReservation" :native-value="$t('other')">
          {{ $t('other') }}
        </b-radio>
      </b-field>
      <b-field :label="$t('comment')">
        <b-input-auto v-model="form.questions.dcReservationComment" placeholder=""/>
      </b-field>
    </div>

    <div class="mt-5 mb-5">
      <div class="mt-5 mb-5 is-size-7">* {{ $t('requiredField') }}</div>

      <b-button class="mr-5" @click="$emit('back')">{{ $t('back') }}</b-button>
      <b-button class="mr-5" @click="$emit('next')">{{ $t('next') }}</b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import bInputAuto from '@/components/bInputAuto.vue'

export default {
  components: {
    bInputAuto
  },
  computed: mapState([
    'form'
  ]),
  data() {
    return {
      messageCountData: ['> 100 000', '> 500 000', '> 1 000 000', '> 5 000 000', '> 10 000 000', '> 50 000 000', '> 100 000 000'],
    }
  }
}
</script>
