import { render, staticRenderFns } from "./AdminDetailView.vue?vue&type=template&id=88852246&scoped=true&"
import script from "./AdminDetailView.vue?vue&type=script&lang=js&"
export * from "./AdminDetailView.vue?vue&type=script&lang=js&"
import style0 from "./AdminDetailView.vue?vue&type=style&index=0&id=88852246&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88852246",
  null
  
)

export default component.exports