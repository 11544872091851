<template>
  <div class="container">
    <navbar-component/>
    <div class="box box-logo"></div>

    <div class="table-container box">
      <b-table
        :data="items"
        :loading="loading"

        paginated
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"

        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort">

        <b-table-column field="createdAt" label="Дата создания" sortable v-slot="props">
          {{ props.row.createdAt | dateFormat }}
        </b-table-column>

        <b-table-column field="name" label="Имя" sortable v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="email" label="Email" sortable v-slot="props">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="phone" label="Телефон" sortable v-slot="props">
          {{ props.row.phone }}
        </b-table-column>

        <b-table-column field="site" label="Сайт" sortable v-slot="props">
          {{ props.row.site }}
        </b-table-column>

        <b-table-column field="company" label="Название компании" sortable v-slot="props">
          {{ props.row.company | truncate(80) }}
        </b-table-column>

        <b-table-column field="processed" label="Обработано" sortable centered v-slot="props">
          <b-checkbox v-model="props.row.processed" @input="process(props.row)"></b-checkbox>
        </b-table-column>

        <b-table-column v-slot="props">
          <router-link :to="{ name: 'detail', params: { id: props.row._id }}" title="Детальная информация">
            <span class="icon">
              <i class="mdi mdi-eye mdi-24px"></i>
            </span>
          </router-link>
        </b-table-column>

        <b-table-column v-slot="props">
          <span class="icon pointer" title="Удалить" @click="confirmDelete(props.row)">
            <i class="mdi mdi-delete mdi-24px"></i>
          </span>
        </b-table-column>

      </b-table>
    </div>

    <footer-component/>
  </div>
</template>

<script>
import axios from "axios"
import {DateTime} from "luxon";
import NavbarComponent from '@/components/admin/navbarComponent.vue'
import FooterComponent from '@/components/admin/footerComponent.vue'

export default {
  name: 'AdminView',
  components: {
    NavbarComponent,
    FooterComponent,
  },
  data() {
    return {
      items: [],
      total: 0,
      loading: false,
      sortField: 'createdAt',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      perPage: 10,
      page: 1
    }
  },
  created() {
    this.getItemsCount()
    this.getItems()
  },
  methods: {
    async getItems() {
      this.loading = true
      try {
        const res = await axios({url: '/api/answers',
          params: {
            sortBy: this.sortField,
            sortOrder: this.sortOrder,
            perPage: this.perPage,
            page: this.page
          }
        })
        this.items = res.data
        this.loading = false
      } catch(error) {
        this.items = []
        this.total = 0
        this.loading = false
        throw error
      }
    },
    async getItemsCount() {
      this.loading = true
      try {
        const res = await axios({url: '/api/answers-count'})
        this.total = res.data.count
        this.loading = false
      } catch(error) {
        this.loading = false
        throw error
      }
    },
    onPageChange(page) {
      this.page = page
      this.getItems()
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.getItems()
    },
    async process(item) {
      await axios.put('/api/answers/' + item._id, {processed: item.processed})
    },
    async remove(item) {
      const idx = this.items.findIndex(el => el._id == item._id)
      await axios.delete('/api/answers/' + item._id)
      this.items.splice(idx, 1)
      this.$buefy.toast.open('Запись удалена!')
    },
    confirmDelete(item) {
      this.$buefy.dialog.confirm({
        title: 'Удаление',
        message: 'Вы действительно хотите удалить запись?',
        confirmText: 'Удалить',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.remove(item)
      })
    }
  },
  filters: {
    truncate(value, length) {
      return value.length > length ? value.substr(0, length) + '...' : value
    },
    dateFormat(value) {
      if (!value) return
      return DateTime.fromISO(value).toFormat('dd.LL.yyyy HH:mm:ss')
    },
  },
}
</script>

<style>
 .pointer {
    cursor: pointer !important;
 }
 .navbar {
    background-color: #e6f3f9 !important;
    margin-bottom: 1rem;
 }
</style>