import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import { ToastProgrammatic as Toast } from 'buefy'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    form: {
      name: '',
      email: '',
      phone: '',
      site: '',
      company: '',
      sms: {
        isSmsNeeded: true,
        nowSmsSending: [],
        nowSmsSendingComment: undefined,
        isIncomingSmsNeeded: undefined,
        isIMSICheckNeeded: '',
        currentSmsLimitations: '',
      },
      push: {
        isPushNeeded: true,
        mobileAppCount: 0,
        mobileAppDownloads: undefined,
        mobileAppActiveUsers: undefined,
        osRatio: 50,
        pushDeliveryPercent: 60,
        isCascadingNeeded: undefined,
        isCascadingNeededComment: '',
        isPushDirectNeeded: undefined,
        isPushDirectNeededComment: ''
      },
      call: {
        isCallNeeded: true,
        isFlashCallNeeded: undefined,
        isFlashCallNeededComment: undefined,
        simultaneousCalls: undefined,
        callDuration: undefined,
        isIncomingCallsNeeded: undefined,
        isIncomingCallsNeededComment: undefined,
        internetProvider: undefined,
        internetProviderComment: undefined,
        callProtocol: undefined,
        callProtocolComment: undefined,
        isSpeechNeeded: undefined,
        speechLanguages: [],
        speechLanguagesComment: undefined,
        speechChannelsCount: undefined,
        isSpeechBotNeeded: undefined,
        isSpeechBotNeededComment: undefined,
        isIVRNeeded: undefined,
        isIVRNeededComment: undefined,
      },
      questions: {
        channelsAlreadyUsed: [],
        supposedToUse: [],
        isAdditionalChannelNeeded: undefined,
        isAdditionalChannelNeededComment: undefined,
        messageCount: {
          sms: undefined,
          email: undefined,
          viber: undefined,
          whatsapp: undefined,
          vk: undefined,
          ok: undefined,
          ussd: undefined,
          call: undefined,
          webpush: undefined,
          push: undefined,
          other: undefined,
        },
        maxMessagesPerSecond: undefined,
        isBDPNNedded: undefined,
        isBDPNNeddedComment: undefined,
        isDirectConnectionNedded: undefined,
        integrationsCount: undefined,
        protocolIntegration: [],
        protocolIntegrationComment: undefined,
        sourceSystemsIntegration: undefined,
        isCustomProtocolsIntegrationNeeded: [],
        isCustomProtocolsIntegrationNeededComment: undefined,
        importExportRequired: undefined,
        dbmsUsed: undefined,
        dataCenterCount: 'one',
        dcReservation: undefined,
        dcReservationComment: undefined,
      },
      support: {
        supportTime: undefined,
        supportTimeComment: undefined,
        sla: undefined,
        contoursCount: [],
        contoursCountComment: undefined,
        remoteAccess: undefined,
        remoteAccessComment: undefined,
      },
      extendedSupport: {
        supportThirdParty: undefined,
        supportThirdPartyComment: undefined,
        isMonitoringNeeded: undefined,
        isMonitoringNeededComment: undefined,
        isAlertReactionNeeded: undefined,
        isAlertReactionNeededComment: undefined,
        isProActiveMonitoringNeeded: undefined,
        isProActiveMonitoringNeededComment: undefined,
        isInstallUpdateNeeded: undefined,
        isInstallUpdateNeededComment: undefined,
        additionalComment: undefined,
        usabilityRate: undefined,
        whatToAdd: undefined,
      }
    }
  },
  getters: {
    form: state => state.form,
    isAuthenticated: state => !!state.token,
    token: state => state.token,
  },
  mutations: {
    setToken: (state, payload) => {
      state.token = payload
    },
  },
  actions: {
    auth: async (context, payload) => {
      try {
        const res = await axios.post('/api/auth', payload)
        localStorage.setItem('token', res.data.token)
        context.commit('setToken', res.data.token)
        axios.defaults.headers.common['Authorization'] = res.data.token
      } catch(err) {
        Toast.open(err.message)
      }
    },
    logout: async (context) => {
      localStorage.setItem('token', '')
      context.commit('setToken', '')
    }
  },
  modules: {
  }
})
